import React from "react";
import styled from "styled-components";
import LoadMore from "../../components/load-more/load-more";
import { BlogPostListingInterface } from "../../graphql/blog";
import BlogPostListItem from "../../components/blog-post/blog-post-list-item";

interface BlogPostListProps {
  posts: BlogPostListingInterface;
}

const ITEMS_LIMIT: number = 6;

const BlogPostList: React.FC<BlogPostListProps> = ({ posts }) => {
  const [displayed, setDsiplayed] = React.useState<number>(ITEMS_LIMIT);

  const loadMoreHandler = () => {
    setDsiplayed(prev => prev + ITEMS_LIMIT);
  };

  return (
    <Wrapper>
      <Headline>Blog posts</Headline>
      <PostsWrapper>
        {posts.edges.slice(0, displayed).map((post, index) => (
          <BlogPostListItem key={`post_${index}`} post={post.node} />
        ))}
        {displayed < posts.totalCount && <LoadMore label="Load more" variant="gray2" onClick={loadMoreHandler} />}
      </PostsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs({ className: "px-5 py-7 bg-gray2 tablet:py-16 tablet:px-40" })``;

const Headline = styled.h2.attrs({ className: "font-light text-white text-paragraph text-center mb-6 tablet:text-left tablet:text-paragraphTablet" })``;

const PostsWrapper = styled.div.attrs({ className: "grid gap-4 z-10 relative tablet:grid-cols-2 " })``;

export default BlogPostList;