import React from "react";
import styled from "styled-components";
import { IconX } from "../icons";
import GatsbyLink from "gatsby-link";
import { theme } from "../../styles/styles";
import { BlogPostInterface } from "../../graphql/blog";
import { PimcoreImage } from "../pimcore-image";
import { trim } from "../../utilities/string-helper";

interface BlogPostListItemProps {
  post: BlogPostInterface;
  variant?: "sm" | "lg" | "home" | "expanded";
  expanded?: boolean;
}

interface VariantInterface {
  $variant?: "sm" | "lg" | "home" | "expanded";
}

const BlogPostListItem: React.FC<BlogPostListItemProps> = ({ post, variant = "lg", expanded = false }) => {
  return (
    <Post>
      <PostPhoto to={`/blog/${post.slug}/`} aria-label="open post">
        {post.photo && (
          <PhotoWrapper $variant={variant}>
            <PimcoreImage
              image={post.photo}
              modifier="min-w-full min-h-full object-cover object-center"
              withAspectRatio
            />
          </PhotoWrapper>
        )}
        {!post.photo && (
          <PhotoPlaceholderWrapper $variant={variant}>
            <PhotoPlaceholder>
              <IconX />
            </PhotoPlaceholder>
          </PhotoPlaceholderWrapper>
        )}
      </PostPhoto>
      <PostDetails to={`/blog/${post.slug}/`} $variant={variant}>
        <Date>{post.date}</Date>
        <Title $variant={variant}>{post.title}</Title>
        {'expanded' === variant && (
          <>
            <Introduction>
              {trim(post.introduction)}
            </Introduction>
            <Button>Read more</Button>
          </>
        )}
      </PostDetails>
    </Post>
  );
};

const Post = styled.div.attrs({ className: "bg-gray2 rounded-lg overflow-hidden h-full" })``;

const PostPhoto = styled(GatsbyLink).attrs({ className: "block overflow-hidden" })``;

const PhotoWrapper = styled.div.attrs<VariantInterface>({ className: "w-full" }) <VariantInterface>`
  height: ${({ $variant: variant }) => ((variant === "lg" || variant === 'expanded') ? "11rem" : "9.8rem")};

  @media ${theme.mediaQueries.tablet} {
    height: ${({ $variant: variant }) =>
    (variant === "lg" || variant === 'expanded') ? "18rem" : variant === "home" ? "13.2rem" : "11.5rem"};
  }
`;

const PostDetails = styled(GatsbyLink).attrs<VariantInterface>(({ $variant: variant }) => ({
  className: `block px-7 py-4 ${variant === "lg" ? "h-32 tablet:h-36" : "h-full"} ${variant === "home" ? "bg-gray6" : "bg-gray12"
    }`,
})) <VariantInterface>``;

const Date = styled.span.attrs({
  className: `block font-regular text-sm text-gray5`,
})``;

const Title = styled.h2.attrs<VariantInterface>(({ $variant: variant = "lg" }) => ({
  className: `block text-white ${(variant === "lg" || variant === "expanded")
      ? "font-bold text-paragraph tablet:text-paragraphTablet tablet:font-light"
      : variant === "home"
        ? "font-bold text-white text-benefit leading-tight	"
        : "font-bold text-paragraph"
    }`,
})) <VariantInterface>``;

const PhotoPlaceholderWrapper = styled(PhotoWrapper).attrs<VariantInterface>({
  className: "bg-gray relative",
}) <VariantInterface>``;

const PhotoPlaceholder = styled.div.attrs<VariantInterface>({
  className: "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2",
}) <VariantInterface>`
  height: ${({ $variant: variant }) => (variant === "lg" ? "36rem" : "20rem")};
  width: ${({ $variant: variant }) => (variant === "lg" ? "36rem" : "20rem")};
`;

const Introduction = styled.div.attrs({
  className: "text-paragraph text-gray5 font-regular mt-4 mb-8 tablet:block",
})``;

const Button = styled.span.attrs({
  className:
    "inline-block px-6 py-3.5 tablet:px-7 border-solid border-2 border-yellow text-yellow hover:bg-yellow font-bold rounded-full bg-transparent mr-2.5 hover:text-gray transition-colors",
})``;

export default BlogPostListItem;
