import React from "react";
import styled from "styled-components";
import LoadMore from "../../components/load-more/load-more";
import { PressCoverageListingInterface } from "../../graphql/blog";
import { theme } from "../../styles/styles";

interface PressCoverageProps {
  links: PressCoverageListingInterface;
}

const ITEMS_LIMIT: number = 6;

const getDomain = (url: string) => new URL(url).hostname;

const PressCoverage: React.FC<PressCoverageProps> = ({ links }) => {
  const [displayed, setDsiplayed] = React.useState<number>(ITEMS_LIMIT);

  const loadMoreHandler = () => {
    setDsiplayed((prev) => prev + ITEMS_LIMIT);
  };

  return (
    <Wrapper>
      <Headline>Press coverage</Headline>
      <LinksList>
        {links.edges.slice(0, displayed).map((link, index) => (
          <LinkItem key={`link_item_${index}`}>
            <LinkDetails href={link.node.url.path} target="_blank" rel="nofollow noreferrer">
              <LinkTitle>{link.node.title}</LinkTitle>
              <LinkDomain>{getDomain(link.node.url.path)}</LinkDomain>
            </LinkDetails>
          </LinkItem>
        ))}
        {displayed < links.totalCount && <LoadMore label="Load more" onClick={loadMoreHandler} />}
      </LinksList>
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs({ className: "px-5 py-7 relative z-10 tablet:px-40 tablet:py-16" })``;

const Headline = styled.h2.attrs({ className: "font-light text-white text-paragraphTablet mb-6" })``;

const LinksList = styled.div.attrs({ className: "grid tablet:grid-cols-3 gap-4 relative" })``;

const LinkItem = styled.div.attrs({ className: "rounded-lg overflow-hidden" })``;

const LinkDetails = styled.a.attrs({
  className: "bg-gray12 px-7 py-4 flex flex-col justify-between tablet:px-7 tablet:py-6",
})`
  height: 8rem;

  @media ${theme.mediaQueries.tablet} {
    height: 10rem;
  }
`;

const LinkTitle = styled.span.attrs({ className: "block text-white font-bold text-paragraph" })``;

const LinkDomain = styled.span.attrs({ className: "block text-yellow text-sm" })``;

export default PressCoverage;
