import GatsbyLink from "gatsby-link";
import React from "react";
import styled from "styled-components";
import { ButtonLinkSolid } from "../../components/button";
import HorizontalSlider from "../../components/horizontal-slider/horizontal-slider";
import { IconX } from "../../components/icons";
import { PimcoreImage } from "../../components/pimcore-image";
import { BlogPostListingInterface } from "../../graphql/blog";
import { theme } from "../../styles/styles";
import { trim } from "../../utilities/string-helper";

interface FeaturedBlogPostListProps {
  posts: BlogPostListingInterface;
}

const FeaturedBlogPostList: React.FC<FeaturedBlogPostListProps> = ({ posts }) => {
  return (
    <Wrapper>
      <Headline>Latest news and stories</Headline>
      <SliderWrapper>
        <HorizontalSlider padding="px-5 tablet:px-40 tablet:gap-14">
          {posts.edges.map((post, index) => (
            <Post key={`post_${index}`}>
              <PostPhoto to={`/blog/${post.node.slug}/`}>
              {post.node.photo && <PhotoWrapper><PimcoreImage image={post.node.photo} modifier="h-full w-full object-cover" loading="eager" withAspectRatio /></PhotoWrapper>}
              {!post.node.photo && <PhotoPlaceholderWrapper><PhotoPlaceholder><IconX /></PhotoPlaceholder></PhotoPlaceholderWrapper>}
              </PostPhoto>
              <PostDetails>
                <Date>{post.node.date}</Date>
                <Title to={`/blog/${post.node.slug}/`}>{post.node.title}</Title>
                <Introduction>
                  {trim(post.node.introduction)}
                </Introduction>
                <ButtonWrapper>
                  <ButtonLinkSolid to={`/blog/${post.node.slug}/`}>
                    Read more
                  </ButtonLinkSolid>
                </ButtonWrapper>
              </PostDetails>
            </Post>
          ))}
        </HorizontalSlider>
      </SliderWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs({ className: "pb-8 tablet:pb-16" })``;

const Headline = styled.h2.attrs({
  className:
    "font-light text-white text-paragraph text-center mb-6 tablet:text-paragraphTablet tablet:text-left px-5 tablet:px-40",
})``;

const SliderWrapper = styled.div.attrs({
  className: "z-10 overflow-x-auto relative",
})``;

const Post = styled.div.attrs({
  className: "grid gap-7 mb-8 tablet:gap-11 w-[80vw] tablet:w-full",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-columns: 24.5rem 43rem;
  }
`;

const PostDetails = styled.div.attrs({ className: "tablet:-order-1" })``;

const PostPhoto = styled(GatsbyLink).attrs({
  className: "overflow-hidden rounded-lg block",
})`
  height: 10rem;

  @media ${theme.mediaQueries.tablet} {
    height: 22.5rem;
  }
`;

const Date = styled.div.attrs({
  className: "block text-paragraph text-gray5 font-regular tablet:mb-3",
})``;

const Title = styled(GatsbyLink).attrs({
  className:
    "block text-paragraphTablet font-light text-white tablet:mb-6 tablet:text-menuHamburger tablet:leading-10	",
})``;

const ButtonWrapper = styled.div.attrs({ className: "hidden tablet:block" })``;

const Introduction = styled.div.attrs({
  className: "hidden text-paragraph text-gray5 font-regular mb-12 tablet:block",
})``;

const PhotoWrapper = styled.span.attrs({ className: "block" })`
  height: 10rem;

  @media ${theme.mediaQueries.tablet} {
    height: 22.5rem;
  }
`;

const PhotoPlaceholderWrapper = styled(PhotoWrapper).attrs<HTMLSpanElement>({ className: "bg-gray2 relative" })``;

const PhotoPlaceholder = styled.span.attrs({
  className:
    "block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2",
})`
  height: 20rem;
  width: 20rem;

  @media ${theme.mediaQueries.tablet} {
    height: 45rem;
    width: 45rem;
  }
`;

export default FeaturedBlogPostList;
