import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/styles";
import { IconX } from "../icons";

interface BackgroundXProps {
  repeat?: number;
  desktopOnly?: boolean;
}

export const BackgroundX: React.FC<BackgroundXProps> = ({ repeat = 1, desktopOnly }) => (
  <Background desktopOnly={desktopOnly}>
    {[...Array(repeat)].map((val, index) => <Wrapper key={`x_${index}`}>
      <IconX />
    </Wrapper>)}
  </Background>
);

const Background = styled.div.attrs<{ desktopOnly?: boolean; }>(({ desktopOnly }) => ({
  className: `absolute inset-x-0 bottom-0 overflow-hidden z-x${desktopOnly? ' hidden tablet:block' : ''}`,
})) <{ desktopOnly?: boolean; }>`
  top: -20rem;

  @media ${theme.mediaQueries.tablet} {
    top: -60rem;
  }
`;

const Wrapper = styled.div.attrs({ className: '-translate-x-1/2' })`
    margin-left: 50%;
    width: 250vw;

    @media ${theme.mediaQueries.tablet} {
        width: 180vw;
    }
`;