import React from "react";
import styled from "styled-components";
import { ButtonSolid } from "../button";

interface LoadMoreProps {
  label: string;
  variant?: "gray2" | "gray";
  height?: string;
  onClick?: () => void;
}

interface WrapperProps {
  $variant?: "gray2" | "gray";
  $height?: string;
}

const LoadMore: React.FC<LoadMoreProps> = ({ label, height, variant = "gray", onClick }) => {

  const onClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  }

  return (
    <Wrapper $variant={variant} $height={height}>
      <ButtonSolid href={'#'} onClick={onClickHandler}>{label}</ButtonSolid>
    </Wrapper>
  )
};

const Wrapper = styled.div.attrs<WrapperProps>(
  ({ $variant = "gray2", $height = 'h-72' }) => ({
    className: `absolute bottom-0 left-0 right-0 flex justify-center items-center bg-gradient-to-b from-transparent ${$height} ${$variant === 'gray'? 'via-gray to-gray' : 'via-gray2 to-gray2'}`
  })) <WrapperProps>``;

export default LoadMore;