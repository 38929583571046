import React from "react";
import styled from "styled-components";
import { BackgroundX } from "../components/background-x";
import { JobsSearchTile, TileNavigation } from "../components/tile-navigation";
import FeaturedBlogPostList from "../sections/blog/featured-blog-post-list";
import BlogPostList from "../sections/blog/blog-post-list";
import PressCoverage from "../sections/blog/press-coverage";
import Layout from "../components/layout";
import { graphql, PageProps } from "gatsby";
import { BlogPostListingInterface, PressCoverageListingInterface } from "../graphql/blog";
import { JobOffersCountInterface } from "../graphql/jobs";
import { BlogPageContentInterface } from "../graphql/blog-page";

interface BlogPageDataInterface {
  pimcore: {
    content: BlogPageContentInterface;
    blogPosts: BlogPostListingInterface;
    featuredBlogPosts: BlogPostListingInterface;
    pressCoverages: PressCoverageListingInterface;
    jobOffersCount: JobOffersCountInterface;
  }
}

const BlogPage: React.FC<PageProps<BlogPageDataInterface>> = ({ data }) => {
  const content = data.pimcore.content;

  return (
    <Layout
      navCurrentItem="blog"
      title={content.metaTitle}
      description={content.metaDescription}
      canonical={content.metaCanonical}
      openGraph={{ title: content.metaOgTitle, description: content.metaOgTitle, image: content.metaOgImage }}
      schemaOrg={content.metaSchema}
    >
      <Main>
        <BackgroundX />
        <Headline>Blog</Headline>
        <FeaturedBlogPostList posts={data.pimcore.featuredBlogPosts} />
        <BlogPostList posts={data.pimcore.blogPosts} />
        <PressCoverage links={data.pimcore.pressCoverages} />
        <TileNavigation top={"team"} bottom={"office"}>
          <JobsSearchTile jobsCount={data.pimcore.jobOffersCount.totalCount} />
        </TileNavigation>
      </Main>
    </Layout>
  );
};

const Main = styled.main.attrs({ className: "pt-20 tablet:pt-32" })``;

const Headline = styled.h1.attrs({ className: "text-subHeadlineMobile text-white text-center font-bold mb-4 tablet:hidden" })``;

export const query = graphql`
  query {
    pimcore {
      ...blogPageData
      ...blogPosts
      ...featuredBlogPosts
      ...pressCoverages
      ...jobOffersCount
    }
  }
`;

export default BlogPage;